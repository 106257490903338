<template>
  <PageListLayout
    :title="`${editing ? 'Cập nhật' : 'Thêm'} báo giá`"
    noFilter
    useBack
    linkBack="/kinhdoanh/quanlybaogia"
  >
    <template slot="side-right">
      <div class="d-flex">
        <v-btn
          class="mr-4"
          v-if="editing"
          color="primary"
          @click="addContract"
          outlined
          ><v-icon class="mr-1"> mdi-lpus</v-icon>Tạo hợp đồng</v-btn
        >
        <v-btn
          class="mr-4"
          v-if="editing"
          :outlined="!isHistory"
          :depressed="isHistory"
          :color="isHistory ? 'primary' : ''"
          @click="isHistory = !isHistory"
        >
          <v-icon class="mr-1"> mdi-timetable</v-icon>
          {{ `${isHistory ? "Ẩn" : "Hiện"} lịch sử` }}
        </v-btn>

        <v-btn
          outlined
          :disabled="showBtnCanBeUpdate"
          dark
          v-for="(item, index) in approveButtons.filter((x) => x.isShow)"
          v-on="{ ...item.onEvent }"
          v-bind="item.props"
          :loading="btnLoadingCheckStatusUpdate"
          class="mr-4"
          :key="`button-${index}`"
        >
          <span class="mr-1">{{ item.text }}</span>
          <v-icon>{{ item.icon }}</v-icon>
        </v-btn>
        <v-tooltip bottom v-if="editing" outlined>
          <template #activator="{ on: onTooltip }">
            <v-menu v-if="editing" offset-y v-on="onTooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  outlined
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="{ ...onTooltip, ...on }"
                  :loading="btnLoadingPrint"
                  class="mr-4"
                >
                  <v-icon> mdi-printer</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item-group>
                  <v-list-item
                    v-for="(item, index) in itemsLang"
                    :key="index"
                    :title="`In báo giá theo ${item.title.toLowerCase()}`"
                    @click="showDiaLogPrintBaogia(item.key, item.title)"
                  >
                    <div class="d-flex">
                      <v-img :src="item.img"></v-img>
                      <v-list-item-title class="ml-2">{{
                        item.title
                      }}</v-list-item-title>
                    </div>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </template>
          <span> In Báo giá</span>
        </v-tooltip>

        <v-tooltip bottom v-if="editing" outlined>
          <template #activator="{ on: onTooltip }">
            <v-menu v-if="editing" offset-y v-on="onTooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  outlined
                  color="#7F00FF"
                  dark
                  v-bind="attrs"
                  v-on="{ ...onTooltip, ...on }"
                  :loading="btnLoadingPrintCompetitor"
                  class="mr-4"
                >
                  <v-icon> mdi-printer</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item-group>
                  <v-list-item
                    v-for="(item, index) in itemsTypeBaogia"
                    :key="index"
                    :title="`In báo giá theo ${item.title.toLowerCase()}`"
                    @click="printCompetitor(item.key, item.title)"
                  >
                    <div class="d-flex">
                      <v-list-item-title class="ml-2">{{
                        item.title
                      }}</v-list-item-title>
                    </div>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </template>
          <span> In Báo giá đối thủ</span>
        </v-tooltip>
        <v-btn
          class="mr-4"
          depressed
          color="success"
          @click="submitUpdate"
          v-if="editing"
          :disabled="disableUpdate"
        >
          <v-icon left>mdi-account-edit</v-icon>
          Cập nhật
        </v-btn>
        <v-btn class="mr-4" depressed color="success" @click="submit" v-else>
          <v-icon left>mdi-account-plus </v-icon>
          Tạo báo giá
        </v-btn>
      </div>
    </template>
    <div v-if="!isHistory">
      <v-form ref="form">
        <v-card-title class="pt-0 pl-6">Báo giá</v-card-title>
        <v-layout column class="pl-6 pr-6">
          <v-row>
            <v-col cols="3" class="zero-vertical">
              <div class="label-form">Yêu cầu báo giá</div>
              <SelectAsync
                prepend-inner-icon="mdi-folder-account"
                v-model="form.request_id"
                :api="getProductPriceList"
                outlined
                dense
                :disabled="editing ? true : false"
                :item-text="
                  (item) =>
                    `${item.code} ${handleRequestTypeList(item.request_type)}`
                "
                item-value="id"
                @change="onCallback($event)"
                :rules="editing ? [] : request_idRules"
                :loading="loadingProductPriceRequest"
              >
              </SelectAsync>
            </v-col>
            <v-col cols="3" class="zero-vertical">
              <div class="label-form">Mã báo giá</div>
              <v-text-field
                v-model="form.price_code"
                placeholder="Mã báo giá"
                outlined
                dense
                prepend-inner-icon="mdi-map-marker"
                :rules="price_codeRules"
                :loading="loadingPriceCode"
                :readonly="editing"
              ></v-text-field>
            </v-col>
            <v-col cols="3" class="zero-vertical">
              <div class="label-form">Ngày báo giá</div>
              <DateTimePicker
                v-model="form.price_date"
                :input-readonly="false"
                format="DD/MM/YYYY"
                :rules="price_dateRules"
                hideDetails="auto"
              ></DateTimePicker>
            </v-col>

            <v-col cols="3" class="zero-vertical">
              <div class="label-form">Loại báo giá</div>
              <v-autocomplete
                prepend-inner-icon="mdi-folder-account"
                v-model="form.product_price_type"
                :items="productPriceTypes"
                :rules="rule_productsPriceType"
                outlined
                dense
                item-text="name"
                item-value="id"
                :readonly="editing ? true : false"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="3" class="zero-vertical">
              <div class="label-form">Địa chỉ email</div>
              <v-text-field
                v-model="form.customer_email"
                placeholder="Địa chỉ khách hàng"
                outlined
                dense
                prepend-inner-icon="mdi-map-marker"
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="3" class="zero-vertical">
              <!-- <v-col :cols="!isReplace ? 8 : 6" class="zero-vertical"> -->
              <div class="label-form">Đơn vị phát sinh</div>
              <v-select
                prepend-inner-icon="mdi-folder-account"
                v-model="form.customer_id"
                :items="customersList"
                outlined
                dense
                item-text="name"
                item-value="id"
                :rules="customer_idRules"
                disabled
              >
              </v-select>
            </v-col>
            <v-col cols="3" class="zero-vertical" v-if="isReplace">
              <div class="label-form">Mã hợp đồng thay thế</div>
              <v-autocomplete
                prepend-inner-icon="mdi-folder-account"
                v-model="form.contract_id"
                :items="listContract"
                outlined
                dense
                item-text="contract_code"
                item-value="id"
                :rules="contract_idRules"
                disabled
              >
                <template slot="no-data">
                  <div class="text-center">
                    {{
                      form.customer_id
                        ? "Chưa có hợp đồng nào"
                        : "Vui lòng chọn khách hàng"
                    }}
                  </div>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="3" class="zero-vertical">
              <div class="label-form">Địa chỉ</div>
              <v-text-field
                v-model="form.address"
                placeholder="Địa chỉ khách hàng"
                outlined
                dense
                prepend-inner-icon="mdi-map-marker"
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="3" class="zero-vertical">
              <div class="label-form">Thuế VAT</div>
              <v-select
                prepend-inner-icon="mdi-format-list-bulleted"
                v-model="form.type_vat"
                :items="VAT_LIST"
                outlined
                dense
                item-text="name"
                item-value="value"
                placeholder="Thuế VAT"
              >
              </v-select>
            </v-col>
            <v-col cols="12" class="zero-vertical">
              <div class="label-form">Ghi chú</div>
              <v-textarea
                v-model="form.description"
                placeholder="Ghi chú"
                outlined
                dense
                prepend-inner-icon="mdi-note"
                rows="2"
                no-resize
              ></v-textarea>
            </v-col>
          </v-row>
        </v-layout>
      </v-form>
      <ProductComponent
        :productsList="productsList"
        v-model="form.ProductPriceDetail"
        title="Sản phẩm báo giá"
        :table="TABLE"
        hide-add-product
      />
      <DiaLogPrintBaoGiaComponent
        ref="formPrintBaoGia"
        @submit="print($event)"
        title="Chọn công ty in báo giá"
      ></DiaLogPrintBaoGiaComponent>
    </div>
    <div v-else>
      <History :dataHistory="dataHistory" />
    </div>
    <!-- Sign Digital by Chien -->
    <SigiDigital
      ref="sign-digital"
      width="500px"
      @save-sign="saveSign"
      @missing-sign="
        (message) => {
          errorNotify(message);
        }
      "
    />
    <!-- Sign Digital by Chien -->
  </PageListLayout>
</template>
<script>
import {
  getSourceProductPriceRequest,
  addProductPrices,
  editProductPrices,
  getProductPricesById,
  getProductPricesCount,
  printProductPrices,
  printProductPriceCompetitor,
  updateStatusProductPrices,
} from "@/api/baogia";
import { getCompanyManagers } from "@/api/congty";
import KHACHHANG from "@/api/khachhang";
import chatThaiAPI from "@/api/sx.chatthai.api";
import JsonToFromData from "@/utils/JsonToFormData.js";
import {
  TABLE,
  VAT_LIST,
  STATUS_LIST,
  STATUS_TITLE,
  ROLE_LIST,
  NEWLY_CREATED,
  PENDING_INTERNAL_APPROVAL,
  APPROVED_INTERNALLY_AND_PENDING_CUSTOMER_APPROVAL,
  ACCEPT_AND_CREATE_A_CONTACT,
  ACCEPT_NO_CONTRACT,
  CUSTOMER_REJECT,
  REJECT,
  STATUS_ACTION_COMFIRM,
} from "@/constants/baogia";
import History from "./history.vue";
import { RequestTypeList } from "@/constants/yeucaubaogia";
import { getContracts } from "@/api/hopdong";
import { getDanhMucCon } from "@/api/danhmuc.js";
import { formatDate } from "@/utils/date";
import { saveAs } from "file-saver";
import { TYPE } from "@/constants/congtynhapkhau";
import loaiChatThaiAPI from "@/api/sx.loaichatthai.api";
import ProductComponent from "../SanPhamComponent/Product.vue";
import DiaLogPrintBaoGiaComponent from "./dialog.vue";
import { mapGetters } from "vuex";
import {
  SYSADMIN_CODE,
  ADMIN_CODE,
  EMPLOYEE_CODE,
  CUSTOMERS_CODE,
  BUSINESS_CODE,
  LOGISTICS_CODE,
  MANAGER_CODE,
} from "@/constants/role";
import SelectAsync from "@/components/Async/SelectAsync";
import SigiDigital from "../../../components/SignDigital/SigiDigital.vue";
export default {
  components: {
    History,
    ProductComponent,
    DiaLogPrintBaoGiaComponent,
    SelectAsync,
    SigiDigital,
  },
  data: () => ({
    APPROVED_INTERNALLY_AND_PENDING_CUSTOMER_APPROVAL,
    TABLE,
    VAT_LIST,
    STATUS_LIST,
    ROLE_LIST,
    loadingPriceCode: false,
    btnLoadingPrint: false,
    btnLoadingCheckStatusUpdate: false,
    btnLoadingRejectStatusUpdate: false,
    btnLoadingPrintCompetitor: false,
    isHistory: false,
    productPriceTypes: [],
    productTypesList: [],
    dataHistory: [],
    showPickRequestDate: false,
    showPickResponseDate: false,
    date: null,
    editing: false,
    customersList: [],
    productsList: [],
    ProductData: [],
    selectedData: [],
    requestTypeList: RequestTypeList,
    tab: null,
    btnLoading: false,
    request_idRules: [(v) => !!v || "Mã yêu cầu không thể bỏ trống"],
    price_codeRules: [(v) => !!v || "Mã báo giá không thể bỏ trống"],
    price_dateRules: [(v) => !!v || "Ngày báo giá không thể bỏ trống"],
    rule_productsPriceType: [(v) => !!v || "Loại báo giá không thể bỏ trống"],
    customer_idRules: [(v) => !!v || "Khách hàng không thể bỏ trống"],
    form: {
      request_id: null,
      price_code: "",
      price_date: "",
      price_by_id: null,
      price_by: "",
      price_by_phone: "",
      customer_id: null,
      customer_name: "",
      address: "",
      customer_email: "",
      status: null,
      description: "",
      ProductPriceDetail: [],
      type_vat: 2,
    },
    showBtnRejectStatus: true,
    showBtnCanBeUpdate: false,
    codeProductRules: [(v) => !!v || "Ngày yêu cầu báo giá không thể bỏ trống"],
    nameProductRules: [(v) => !!v || "Ngày yêu cầu báo giá không thể bỏ trống"],
    unitProductRules: [(v) => !!v || "Đơn vị tính không thể bỏ trống"],
    expected_priceProductRules: [(v) => !!v || "Đơn giá không thể bỏ trống"],
    productPriceRequestList: [],
    isReplace: false,
    contract_idRules: [(v) => !!v || "Mã hợp đồng không thể bỏ trống"],
    listContract: [],
    loadingProductPriceRequest: false,
    itemsLang: [
      {
        title: "Tiếng Việt",
        img: require("../../../assets/images/lang/vietnam-flag-icon-32.png"),
        key: "vn",
      },
      {
        title: "Tiếng Anh",
        img: require("../../../assets/images/lang/united-states-of-america-flag-icon-32.png"),
        key: "en",
      },
      {
        title: "Tiếng Trung",
        img: require("../../../assets/images/lang/china-flag-icon-32.png"),
        key: "cn",
      },
    ],

    ListCompany: [],
    itemsTypeBaogia: [
      {
        title: "Anh Đăng",
        key: "anhdang",
      },
      {
        title: "Việt Xuân Mới",
        key: "vietxuanmoi",
      },
      {
        title: "MTVN",
        key: "mtvn",
      },
      {
        title: "Ngôi sao xanh",
        key: "nsx",
      },
      {
        title: "Ngôi sao xanh Quế Võ",
        key: "nsx-qv",
      },
      {
        title: "Việt Tiến",
        key: "viettien",
      },
    ],

    total: null,
    page: 1,
    perPage: 10,
    loadmorable: false,
  }),

  created() {
    if (this.$route.params.id) {
      this.editing = true;
      this.getProductPriceInfo(this.$route.params);
    } else {
      this.fetchProductPricesCount();
    }

    // this.getAllCompanyManagers();
  },

  mounted() {
    this.getProductPriceList();
    this.getAllCompanyManagers();
    this.getCustomersList();
    this.fetchProductPriceType();
    this.getProductsList();
    this.getProductTypesList();
  },

  computed: {
    ...mapGetters({
      currentUser: "User/getMe",
    }),
    formTitle() {
      return this.editedIndex === -1 ? "Thêm sản phẩm" : "Sửa sản phẩm";
    },
    computedDateFormatted() {
      return this.formatDate(this.form.price_date);
    },
    disableUpdate() {
      let baseRole = [SYSADMIN_CODE, ADMIN_CODE];
      if (!this.editing) {
        return true;
      }

      if (
        this.form?.status != NEWLY_CREATED ||
        ![...baseRole, BUSINESS_CODE, LOGISTICS_CODE].includes(
          this.currentUser?.role?.code
        )
      ) {
        return true;
      }

      return false;
    },
    approveButtons() {
      let baseRole = [SYSADMIN_CODE, ADMIN_CODE];
      let result = [];
      switch (this.form.status) {
        case NEWLY_CREATED:
          result = [
            {
              text: "Gửi yêu cầu duyệt",
              icon: "mdi-checkbox-marked-circle",
              props: {
                color: "primary",
              },
              onEvent: {
                click: ($event) =>
                  this.comfirmUpdateStatus(
                    this.form.id,
                    PENDING_INTERNAL_APPROVAL
                  ),
              },
              isShow: [...baseRole, BUSINESS_CODE, LOGISTICS_CODE].includes(
                this.currentUser?.role?.code
              ),
            },
          ];
          break;
        case PENDING_INTERNAL_APPROVAL:
          result = [
            {
              text: "Phê duyệt",
              icon: "mdi-checkbox-marked-circle",
              props: {
                color: "primary",
              },
              onEvent: {
                click: ($event) => this.openSignApproved(this.form.id),
                // click: ($event) =>
                //   this.onUpdateStatus(
                //     this.form.id,
                //     APPROVED_INTERNALLY_AND_PENDING_CUSTOMER_APPROVAL
                //   ),
              },
              isShow: [...baseRole, MANAGER_CODE].includes(
                this.currentUser?.role?.code
              ),
            },
            {
              text: "Từ chối duyệt",
              icon: "mdi-cancel",
              props: {
                color: "#d32f2f",
              },
              onEvent: {
                click: ($event) =>
                  this.comfirmUpdateStatus(this.form.id, REJECT),
              },
              isShow: [...baseRole, MANAGER_CODE].includes(
                this.currentUser?.role?.code
              ),
            },
            {
              text: "Đang chờ phê duyệt",
              icon: "mdi-receipt-text-clock-outline",
              props: {
                color: "#fb3",
              },
              isShow: [BUSINESS_CODE, LOGISTICS_CODE].includes(
                this.currentUser?.role?.code
              ),
            },
          ];
          break;
        case APPROVED_INTERNALLY_AND_PENDING_CUSTOMER_APPROVAL:
          result = [
            {
              text:
                STATUS_TITLE[APPROVED_INTERNALLY_AND_PENDING_CUSTOMER_APPROVAL],
              icon: "mdi-receipt-text-clock-outline",
              props: {
                readonly: true,
                color: "#fb3",
              },
              onEvent: {},
              isShow: true,
            },
          ];
          break;

        case ACCEPT_AND_CREATE_A_CONTACT:
          result = [
            {
              text: STATUS_TITLE[ACCEPT_AND_CREATE_A_CONTACT],
              icon: "mdi-checkbox-marked-circle",
              props: {
                readonly: true,
                color: "primary",
              },
              onEvent: {},
              isShow: true,
            },
          ];
          break;
        case ACCEPT_NO_CONTRACT:
          result = [
            {
              text: STATUS_TITLE[ACCEPT_NO_CONTRACT],
              icon: "mdi-checkbox-marked-circle",
              props: {
                readonly: true,
                color: "primary",
              },
              onEvent: {},
              isShow: true,
            },
          ];
          break;
        case CUSTOMER_REJECT:
          result = [
            {
              text: STATUS_TITLE[CUSTOMER_REJECT],
              icon: "mdi-checkbox-marked-circle",
              props: {
                readonly: true,
                color: "#d32f2f",
              },
              onEvent: {},
              isShow: true,
            },
          ];
          break;
        case REJECT:
          result = [
            {
              text: STATUS_TITLE[REJECT],
              icon: "mdi-checkbox-marked-circle",
              props: {
                readonly: true,
                color: "#d32f2f",
              },
              onEvent: {},
              isShow: true,
            },
          ];
          break;
        default:
          break;
      }
      return result;
    },
  },
  watch: {
    "form.customer_id": function(val) {
      this.getContract(val);
    },
  },
  methods: {
    addContract() {
      console.log(`/kinhdoanh/themhopdong/${this.$route.params.id}`);
      this.$router.push({
        path: `/kinhdoanh/thembaogia/themhopdong/${this.$route.params.id}`,
      });
    },
    async getAllCompanyManagers() {
      let res = await getCompanyManagers({
        type: TYPE,
        is_paginate: false,
      });
      this.ListCompany = res.data;
    },
    async comfirmUpdateStatus(id, status, data = {}) {
      if (STATUS_ACTION_COMFIRM[status]) {
        this.$confirmBox.show({
          title: STATUS_ACTION_COMFIRM[status]["title"],
          width: 500,
          body: STATUS_ACTION_COMFIRM[status]["description"],
          disableMessage: true,
          action: () => {
            if (data.clearSign) {
              delete data.clearSign;
            }
            this.onUpdateStatus(id, JsonToFromData({ status, ...data }));
          },
        });
      } else {
        this.onUpdateStatus(id, JsonToFromData({ status, ...data }));
      }
    },
    async onUpdateStatus(id, data = {}) {
      try {
        await updateStatusProductPrices(id, data);
        // this.$refs["sign-component"].close();
        this.$toast.info("Cập nhật thành công", {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
        });
        this.getProductPriceInfo(this.$route.params);
      } catch (error) {
        this.errorNotify("Lỗi cập nhật trạng thái báo giá");
      } finally {
        this.btnLoadingCheckStatusUpdate = false;
        this.btnLoadingRejectStatusUpdate = false;
      }
    },
    showDiaLogPrintBaogia(language, key_title) {
      this.$refs.formPrintBaoGia.show(this.ListCompany, language, key_title);
    },
    async fetchProductPriceType() {
      const res = await getDanhMucCon({ code: "LOAIBAOGIA", isActive: true });
      this.productPriceTypes = res;
    },
    async getProductTypesList() {
      let res = await loaiChatThaiAPI.list();
      this.productTypesList = res?.data;
    },
    async fetchProductPricesCount() {
      try {
        this.loadingPriceCode = true;
        const res = await getProductPricesCount();
        this.form.price_code = `BG${res}`;
      } finally {
        this.loadingPriceCode = false;
      }
    },
    async getContract(customer_id) {
      const res = await getContracts({
        customer_id,
        pagination: false,
      });
      this.listContract = res;
    },
    handleRequestTypeList(value) {
      let find = this.requestTypeList.find((x) => x.value == value);
      if (find) {
        return `- ${find.name}`;
      }
      return "";
    },
    formatDate(e) {
      if (!e) return null;
      try {
        let date = new Date(e);
        let ngay = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
        let thang =
          Number(date.getMonth() + 1) < 10
            ? "0" + Number(date.getMonth() + 1)
            : Number(date.getMonth() + 1);
        return ngay + "/" + thang + "/" + date.getFullYear();
      } catch (error) {
        return "";
      }
    },
    async getProductPriceInfo(id) {
      let data = await getProductPricesById(id);
      this.form = data;

      this.ProductData = data.product_price_detail;
      this.form.ProductPriceDetail = data.product_price_detail;
      this.dataHistory = JSON.parse(data.histories);
    },
    async submit() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.btnLoading = true;
        try {
          let data = JsonToFromData({ ...this.form }, null);
          await addProductPrices(data);
          this.alert(true);
          this.$router.push("/kinhdoanh/quanlybaogia");
        } finally {
          this.btnLoading = false;
          this.show = false;
        }
      }
    },
    async getCustomersList() {
      let res = await KHACHHANG.getAllCustomers();
      this.customersList = res.data;
    },
    alert(isCreate) {
      this.$emit("on-done");
      this.$store.dispatch(
        "toastAlert/success",
        `${isCreate ? "Thêm mới" : "Cập nhật"} thành công`
      );
    },
    async getProductsList() {
      let res = await chatThaiAPI.list();
      this.productsList = res?.data;
    },
    getProductPriceList(params) {
      return getSourceProductPriceRequest(params);
    },

    async submitUpdate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.btnLoading = true;
        try {
          let data = { ...this.form };
          data = JsonToFromData(data);
          await editProductPrices(this.form.id, data);
          this.show = false;
          this.btnLoading = false;
          this.alert(false);
          //this.$router.push("/kinhdoanh/quanlybaogia");
        } finally {
          this.btnLoading = false;
          this.show = false;
        }
      }
    },
    onCallback(item) {
      this.form.customer_id = item?.customer_id;
      this.form.contract_id = item?.contract_id;
      this.form.customer_name = item?.customer_name;
      this.form.address = item?.customer_address;
      this.form.customer_email = item?.customers?.email;
      this.ProductData = item?.product_price_requests_details;
      this.form.ProductPriceDetail = this.ProductData?.map((x) => {
        x.product_price = x.expected_price;
        x.expected_price = null;
        return x;
      });
      this.isReplace = find?.request_type == 2 || false;
    },

    dateFormat(e) {
      return formatDate(e);
    },
    async printCompetitor(language, key_title) {
      try {
        this.btnLoadingPrintCompetitor = true;
        const res = await printProductPriceCompetitor(this.form.id, {
          language,
        });
        if (res.isError) {
          this.errorNotify("Lỗi in báo giá");
          return;
        }
        saveAs(new Blob([res]), `BAO_GIAO_${key_title}.docx`);
      } catch (error) {
        this.errorNotify("Lỗi in báo giá");
      } finally {
        this.btnLoadingPrintCompetitor = false;
      }
    },
    async print(event) {
      try {
        this.btnLoadingPrint = true;
        const res = await printProductPrices(this.form.id, {
          type: event.data.type,
          language: event.language,
          key_title: event.key_title,
        });
        if (res.isError) {
          this.errorNotify("Lỗi in báo giá");
          return;
        }
        saveAs(new Blob([res]), `Chi_tiet_bao_gia_${event.key_title}.docx`);
      } catch (error) {
        this.errorNotify("Lỗi in báo giá");
      } finally {
        this.btnLoadingPrint = false;
      }
    },
    errorNotify(message) {
      this.$store.dispatch("toastAlert/error", message);
    },
    openSignApproved(id) {
      this.$refs["sign-digital"].open({ id });
    },
    saveSign(data) {
      this.comfirmUpdateStatus(
        this.form.id,
        APPROVED_INTERNALLY_AND_PENDING_CUSTOMER_APPROVAL,
        data
      );
    },
  },
};
</script>
<style scoped>
#signature {
  border: double 3px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #4bc5e8, #9f6274);
  background-origin: border-box;
  background-clip: content-box, border-box;
}
.zero-vertical {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
::v-deep .v-list-item.header__right__list_item {
  height: 40px;
}
::v-deep .header__right__list_item .v-list-item__content {
  padding-bottom: 0;
}
</style>
