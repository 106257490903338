<template>
  <div>
    <div v-if="dataHistory && dataHistory.length">
      <div class="pb-6" style="font-size: 22px; font-weight: bold">
        <v-icon style="color: black" size="36"> mdi-history</v-icon> Lịch sử cập
        nhật báo giá
      </div>
      <v-row>
        <v-col cols="6" v-for="(item, index) in this.dataHistory" :key="index">
          <v-card class="mx-auto" elevation="6">
            <v-card-title>
              <v-icon class="pr-3" size="30">mdi-calendar-clock</v-icon>
              Cập nhật lần {{ index + 1 }} - Lúc
              {{ formatDateTime(item.updated_at) }}
            </v-card-title>
            <v-card-text>
              <div>
                Yêu cầu báo giá: <b>{{ item.yeu_cau_bao_gia }}</b>
              </div>
              <div>
                Mã báo giá: <b>{{ item.price_code }}</b>
              </div>
              <div>
                Đơn vị phát sinh: <b>{{ item.customer_name }}</b>
              </div>
              <div>
                Ngày báo giá: <b>{{ item.price_date }}</b>
              </div>
            </v-card-text>
            <div class="pa-3">
              <div class="pb-2">Sản phẩm báo giá</div>
              <CustomTable
                class="mb-2"
                :headers="headers"
                :items="item.product_price_detail"
                hide-default-footer
                :page.sync="page"
                @change-page="(val) => (page = val)"
              >
                <template v-slot:[`item.url_image`]="{ item }">
                  <img
                    height="50px"
                    width="50px"
                    :src="
                      item.url_image
                        ? item.url_image
                        : item.image && getImageUrl(item.image.link)
                    "
                    v-if="item.image || item.url_image"
                  />
                </template>
                <!-- <template v-slot:top>
                <v-toolbar flat>


                </v-toolbar>
              </template> -->
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon small class="mr-2" @click="editItem(item)">
                    mdi-pencil
                  </v-icon>
                  <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
                </template>
              </CustomTable>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <v-layout v-else justify-center align-center style="height: 600px" column>
      <v-icon class="pr-3" size="80" color="pink">mdi-calendar-clock</v-icon>
      <div class="pt-4" style="font-size: 20px">Không có lịch sử cập nhật</div>
    </v-layout>
  </div>
</template>

<script>
export default {
  props: ["dataHistory"],
  data: () => ({
    page: 1,
    headers: [
      {
        text: "Tên sản phẩm",
        align: "start",
        sortable: false,
        value: "product_name",
      },
      { text: "ĐVT", value: "unit" },
      { text: "Đơn giá", value: "expected_price" },
      { text: "Đơn giá mới", value: "product_price" },
      { text: "Hình ảnh", value: "url_image" },
      { text: "Ghi chú", value: "description" },
    ],
  }),
  mounted() {},
  methods: {
    formatDateTime(e) {
      try {
        let time = new Date(e);
        let date = +time.getDate() < 10 ? "0" + time.getDate() : time.getDate();
        let month =
          +time.getUTCMonth() + 1 < 10
            ? "0" + (+time.getUTCMonth() + 1)
            : +time.getUTCMonth() + 1;
        let year = +time.getUTCFullYear();
        let hour =
          +time.getHours() < 10 ? "0" + time.getHours() : time.getHours();
        let min =
          +time.getUTCMinutes() < 10
            ? "0" + time.getUTCMinutes()
            : time.getUTCMinutes();
        return hour + ":" + min + " ngày " + date + "/" + month + "/" + year;
      } catch (error) {
        console.log(error);
        return "";
      }
    },
    getImageUrl(url) {
      if (url.charAt(0) === "/") {
        url = url.substring(1);
      }
      let path = `${process.env.VUE_APP_BASE}${url}`;
      return path;
    },
  },
};
</script>

<style>
</style>