export const TABLE_PRODUCT_PRICE_DETAILS = "PRODUCT_PRICE_DETAILS";

export const TABLE = "PRODUCT_PRICES";

export const VAT_LIST = [
  { value: 1, name: "Đơn giá đã bao gồm VAT" },
  { value: 2, name: "Đơn giá chưa bao gồm VAT" },
];

export const NEWLY_CREATED = "NEWLY_CREATED";
export const PENDING_INTERNAL_APPROVAL = "PENDING_INTERNAL_APPROVAL";
export const APPROVED_INTERNALLY_AND_PENDING_CUSTOMER_APPROVAL =
  "APPROVED_INTERNALLY_AND_PENDING_CUSTOMER_APPROVAL";
// export const PENDING_CUSTOMER_APPROVAL = "PENDING_CUSTOMER_APPROVAL";
export const ACCEPT_AND_CREATE_A_CONTACT = "ACCEPT_AND_CREATE_A_CONTACT";
export const ACCEPT_NO_CONTRACT = "ACCEPT_NO_CONTRACT";
export const CUSTOMER_ACCEPT_NO_CONTRACT = "CUSTOMER_ACCEPT_NO_CONTRACT";
export const CUSTOMER_REJECT = "CUSTOMER_REJECT";
export const REJECT = "REJECT";
export const CANCEL = "CANCEL";

export const STATUS_TITLE = {
  [NEWLY_CREATED]: "Mới tạo",
  [PENDING_INTERNAL_APPROVAL]: "Chờ duyệt nội bộ",
  [APPROVED_INTERNALLY_AND_PENDING_CUSTOMER_APPROVAL]:
    "Đã duyệt nội bộ và Chờ khách hàng duyệt",
  // [PENDING_CUSTOMER_APPROVAL]: "Chờ khách hàng duyệt",
  [ACCEPT_AND_CREATE_A_CONTACT]: "Chấp nhận và đã có hợp đồng",
  [ACCEPT_NO_CONTRACT]: "Chấp nhận và chưa có hợp đồng",
  [CUSTOMER_ACCEPT_NO_CONTRACT]: "Chấp nhận (không hợp đồng)",
  [CUSTOMER_REJECT]: "Khách hàng không phê duyệt",
  [REJECT]: "Không được phê duyệt",
  [CANCEL]: "Đã huỷ bỏ",
};

export const STATUS_ACTION_COMFIRM = {
  [PENDING_INTERNAL_APPROVAL]: {
    title: "Chuyển duyệt nội bộ",
    description:
      "Bạn có chắc chắn muốn chuyển trạng thái sang duyệt nội bộ hay không! Lưu ý không thể hoàn tác lại hành động này",
  },
  [APPROVED_INTERNALLY_AND_PENDING_CUSTOMER_APPROVAL]: {
    title: "Phê duyệt báo giá và đưa cho khách hàng",
    description:
      "Phê duyệt báo giá, khách hàng sẽ có thể xem xét báo giá này! Lưu ý không thể hoàn tác lại hành động này",
  },
  [REJECT]: {
    title: "Từ chối phê duyệt báo giá",
    description:
      "Bạn có muốn từ chối phê duyệt báo giá này! Lưu ý không thể hoàn tác lại hành động này",
  },
};

export const STATUS_LIST = [
  { value: NEWLY_CREATED, name: STATUS_TITLE[NEWLY_CREATED] }, //
  {
    value: PENDING_INTERNAL_APPROVAL,
    name: STATUS_TITLE[PENDING_INTERNAL_APPROVAL],
  },
  {
    value: APPROVED_INTERNALLY_AND_PENDING_CUSTOMER_APPROVAL,
    name: STATUS_TITLE[APPROVED_INTERNALLY_AND_PENDING_CUSTOMER_APPROVAL],
  }, //
  // {
  //   value: PENDING_CUSTOMER_APPROVAL,
  //   name: STATUS_TITLE[PENDING_CUSTOMER_APPROVAL],
  // },
  {
    value: ACCEPT_AND_CREATE_A_CONTACT,
    name: STATUS_TITLE[ACCEPT_AND_CREATE_A_CONTACT],
  },
  {
    value: CUSTOMER_ACCEPT_NO_CONTRACT,
    name: STATUS_TITLE[CUSTOMER_ACCEPT_NO_CONTRACT],
  },
  { value: ACCEPT_NO_CONTRACT, name: STATUS_TITLE[ACCEPT_NO_CONTRACT] }, //
  { value: CUSTOMER_REJECT, name: STATUS_TITLE[CUSTOMER_REJECT] },
  { value: REJECT, name: STATUS_TITLE[REJECT] }, //
  { value: CANCEL, name: STATUS_TITLE[CANCEL] }, //
];

export const ROLE_LIST = [
  { code: "sysadmin", value: "System Admin" }, //1
  { code: "admin", value: "Admin" }, //2
  { code: "employee", value: "Employee" }, //4
  { code: "customers", value: "Customers" }, //5
  { code: "business", value: "Business" }, //6
  { code: "logistics", value: "Logistics" }, //7
  { code: "manager", value: "Manager" }, //3
];

export const STYLE_STATUS = {
  [NEWLY_CREATED]: "bg-info", //
  [PENDING_INTERNAL_APPROVAL]: "bg-warning",
  [APPROVED_INTERNALLY_AND_PENDING_CUSTOMER_APPROVAL]: "bg-danger", //
  // PENDING_CUSTOMER_APPROVAL: "bg-customize-color",
  [CUSTOMER_ACCEPT_NO_CONTRACT]: "bg-success",
  [ACCEPT_AND_CREATE_A_CONTACT]: "bg-primary",
  [ACCEPT_NO_CONTRACT]: "bg-info",
  [CUSTOMER_REJECT]: "bg-success", //
  [REJECT]: "bg-gray", //
  [CANCEL]: "bg-gray", //
};
